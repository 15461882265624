<template>
  <el-container :id="titlex" class="carDialogVue">
    <el-card
      class="box-card"
      style="
        width: 350px;
        box-shadow: 0px 2px 8px 0px rgba(200, 200, 200, 0.5);
        border-radius: 4px;
      "
    >
      <i
        style="float: right; padding: 3px 0; cursor: pointer"
        @click.stop="closeDialog()"
        class="el-icon-close"
      ></i>

      <div class="monitor-message">
        <div class="cars-info" @mousedown="mousedown">
          <ul class="info">
            <li class="card-header">
              <div style="width: 100%">
                <img :src="iconUrl[data.status]" />
                <div :title="titlex" class="title">
                  {{ titlex }}
                </div>
                <div style="font-size: 16px" :class="statusText(data.status)">
                  {{ !data.status ? '离线' : status[data.status] }}
                </div>
              </div>
            </li>
            <li style="padding: 0 30px 0 40px">
              <div>
                <span class="car-info">
                  <i class="iconfont its_driver" title="驾驶员"></i>
                </span>
                <p
                  style="font-size: 12px"
                  :title="data.driverName || '未获取到数据'"
                >
                  {{ data.driverName || '未获取到数据' }}
                </p>
              </div>
              <div>
                <span class="car-info">
                  <i class="iconfont its_terminal" title="终端编号"></i>
                </span>
                <p
                  style="font-size: 12px"
                  :title="data.deviceNos || '未获取到数据'"
                >
                  {{ data.deviceNos || '未获取到数据' }}
                </p>
              </div>
            </li>
            <div style="background: #f7f7f7; padding: 10px; margin-top: 5px">
              <li>
                <span>时间：</span>
                <p :title="data.gpsTime">{{ data.gpsTime }}</p>
              </li>
              <!-- <li>
                <span>车辆状态：</span>
                <p
                  :title="status[data.status]"
                  :class="statusText(data.status)"
                >{{status[data.status]}}</p>
              </li>-->
              <li>
                <span>行驶速度：</span>
                <p :title="data.velocity">
                  {{ data.velocity ? data.velocity : 0 }}公里/时
                </p>
              </li>
              <li class="position">
                <span>位置：</span>
                <p v-if="pageAddress && pageAddress.status" class="street" @click="showAdsress() "style="cursor: pointer; color: #336ffe">
                  查看地址
                </p>
                <p v-else class="street" :title="pageAddress">
                  {{ pageAddress }}
                </p>
              </li>
              <li>
                <span>ACC：</span>
                <p>
                  {{
                    data.accStatus === null || data.accStatus == 1
                      ? 'ACC关'
                      : 'ACC开'
                  }}
                </p>
              </li>
              <li>
                <span>定位状态：</span>
                <p
                  class="street"
                  :title="
                    (data.locate === null || data.locate === 1
                      ? '未定位'
                      : '已定位') +
                    getLocateText(
                      data.gps,
                      data.beidou,
                      data.glonass,
                      data.galileo
                    )
                  "
                >
                  {{
                    data.locate === null || data.locate === 1
                      ? '未定位'
                      : '已定位'
                  }}
                  {{
                    getLocateText(
                      data.gps,
                      data.beidou,
                      data.glonass,
                      data.galileo
                    )
                  }}
                </p>
              </li>
              <li>
                <span>报警名称：</span>
                <span class="text4">{{ data.alarmType }}</span>
              </li>
            </div>
            <div class="buttons">
              <el-button
                type="text"
                size="small"
                sort="grey"
                @click="toPlayVideo"
                ><i class="iconfont its_shipin"></i>视频</el-button
              >
              <el-button
                type="text"
                size="small"
                sort="grey"
                @click="toPlayHisVideo"
                ><i class="iconfont its_lishishipin"></i>历史</el-button
              >
              <el-button
                type="text"
                size="small"
                sort="grey"
                @click="toPlayTril"
                ><i class="iconfont its_guijihuifang"></i>轨迹</el-button
              >
            </div>
          </ul>
        </div>
      </div>
    </el-card>
  </el-container>
</template>

<script>
import {getGDPlace} from '@/common/utils/getPlace.js'

export default {
  name: "Window",
  props: {
    titlex: String,
    checkObj: Object,
    area: String,
    street: String,
    address: [String,Object]
  },
  watch: {
    checkObj (curVal, oldVal) {
      if (curVal) {
        this.getData();
      }
    },
    address(n,o){
      this.pageAddress = n;
    }
  },
  data () {
    return {
      color: "#66B1FF",
      title: "",
      activeName: "second",
      data: {},
      carDteailTableVisible: false, // 显示车辆更多详细信息
      iconUrl: [],
      status: [
        "(离线)",
        "(空车)",
        "(重车)",
        "(停运)",
        "(报警)",
        "(行驶)",
        "(在线)"
      ],
      pageAddress:"",
    };
  },
  computed: {
    statusText (value) {
      return function (value) {
        return {
          text0: value === 0,
          text1: value === 1,
          text2: value === 2,
          text3: value === 3,
          text4: value === 4,
          text5: value === 5,
          text6: value === 6
        };
      };
    }
  },
  mounted () {
    this.pageAddress = this.address;
    let system = sessionStorage.getItem("system");
    if (system == 1) {
      this.iconUrl = [
        require("@/assets/images/map/taxi0.svg"), //离线
        require("@/assets/images/map/taxi1.svg"), //空车
        require("@/assets/images/map/taxi2.svg"), //重车
        require("@/assets/images/map/taxi3.svg"), //停运
        require("@/assets/images/map/taxi4.svg"), //报警
        require("@/assets/images/map/taxi5.svg") //电召
      ];
    } else {
      this.iconUrl = [
        require("@/assets/images/map/car0.png"), //离线
        require("@/assets/images/map/car6.png"), //空车
        require("@/assets/images/map/car6.png"), //重车
        require("@/assets/images/map/car6.png"), //停运
        require("@/assets/images/map/car4.png"), //异常
        require("@/assets/images/map/car5.png"), //行驶
        require("@/assets/images/map/car6.png") //在线
      ];
    }
    this.getData();
  },

  methods: {
    showAdsress(){
      this.getLocation(this.checkObj.lonAndLat[0], this.checkObj.lonAndLat[1])
    },
    /* 将经纬度转为地址信息 */
    getLocation(lng, lat) {
      let self = this;
      getGDPlace(lng, lat, false).then(res => {
        if (res) {
          self.pageAddress = res;
        }
      });
    },
    // 获取定位信息
    getLocateText (gps, beidou, glonass, galileo) {
      let textArr = [];
      gps && textArr.push("GPS定位");
      beidou && textArr.push("北斗定位");
      glonass && textArr.push("Glonass定位");
      galileo && textArr.push("Galileo定位");
      return textArr.length ? `(${textArr.join("、")})` : "";
    },
    getData () {
      this.data = { ...this.checkObj };
    },
    closeDialog (e) {
      this.$emit("dialogVisible");
    },
    mousedown (event) {
      this.selectElement = document.getElementById(this.titlex);
      var div1 = this.selectElement;
      this.selectElement.style.cursor = "move";
      this.isDowm = true;
      var distanceX = event.clientX - this.selectElement.offsetLeft;
      var distanceY = event.clientY - this.selectElement.offsetTop;
      document.onmousemove = function (ev) {
        var oevent = ev || event;
        div1.style.left = oevent.clientX - distanceX + "px";
        div1.style.top = oevent.clientY - distanceY + "px";
      };
      document.onmouseup = function () {
        document.onmousemove = null;
        document.onmouseup = null;
        div1.style.cursor = "default";
      };
    },
    /** 点击未处理报警 */
    toUndealAlarm () {
      this.$router.push({
        name: "alarmDeal",
        query: {
          chooseCar: [{ id: this.checkObj.vehId, name: this.checkObj.cph }]
        }
      });
    },
    toPlayVideo () {
      this.$emit("toHandlePlayVideo", `v${this.data.vehId}`, this.titlex);
    },
    toPlayHisVideo () {
      if (this.data.status === 0) {
        this.$message.warning("此车辆未在线");
        return;
      }
      if (this.data.accStatus === 1) {
        this.$message.warning("ACC关,不可查看视频");
        return;
      }
      //这里需要videos参数
      let params = {
        id: `v${this.data.vehId}`,
        name: this.titlex,
        telNo: this.data.deviceNos.replace("[", "").replace("]", ""),
        videos: this.data.videos
      };
      this.$emit("toHandlePlayHisVideo", params);
    },
    toPlayTril () {
      this.$emit("toHandleTrail", `v${this.data.vehId}`, this.titlex);
    }
  }
};
</script>

<style lang="scss" scoped>
.carDialogVue {
  position: absolute;
  width: 350px !important;
  border: 1px;
  top: 63px;
  left: 75%;
  z-index: 2000;
  @include themify() {
    .el-card__body {
      padding-bottom: 5px;
    }
    .monitor-message {
      overflow: hidden;
      .cars-info {
        position: relative;

        .info {
          margin-bottom: 5px;
          list-style: none;
          margin: 0;
          padding: 0;
          li {
            line-height: 23px;
            letter-spacing: -0.34px;
            font-size: 14px;
            span {
              display: inline-block;
              // width: 68px;
              letter-spacing: -0.34px;
              text-align: left;
              float: left;
              white-space: nowrap;
            }
            span.car-info {
              color: themed('b4');
              margin-right: 5px;
            }
            & > div {
              width: 50%;
              float: left;
            }
          }
          li:not(.position) {
            height: 23px;
            p {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              margin: 0;
            }
          }
          //位置
          li.position {
            min-height: 23px;
            line-height: 23px;
            letter-spacing: -0.34px;
            font-size: 14px;
            p {
              margin: 0;
            }
          }
          .card-header {
            & > div {
              width: 100%;
              font-size: 16px;
              & > img {
                position: absolute;
                transform: rotate(-90deg);
                top: 15px;
              }
              & > div.title {
                margin-left: 40px;
                float: left;
                color: #333333;
                margin-right: 5px;
              }
            }
          }
        }
      }
      .buttons {
        display: flex;
        justify-content: space-between;
      }
    }
    .text0 {
      color: #575a5e;
      cursor: pointer;
    }
    .text1 {
      color: #059370;
      cursor: pointer;
    }
    .text2 {
      color: #fec800;
      cursor: pointer;
    }
    .text3 {
      color: #f1924e;
      cursor: pointer;
    }
    .text4 {
      color: #ff1212;
      cursor: pointer;
    }
    .text5 {
      color: themed('g4');
      cursor: pointer;
    }
    .text6 {
      color: #336ffe;
      cursor: pointer;
    }
  }
}
</style>
